<template>
    <div class="Edu">
        <el-tag
            effect="dark"
        >
            实验仿真
        </el-tag>
        <p class="p justify">
            国家虚拟仿真实验教学一流课程建设是教育部为推进现代信息技术融入实验教学课程，提升实验教学质量和水平的重要举措。
        </p>
        <p class="p justify">
            我们构建的虚拟仿真物理实验室，包含对机械力学、材料中热场、电场、磁场、光场等大量虚拟物理场景及其中的物理机制计算模拟。
        </p>
        <p class="p justify">
            用户可通过三维技术模拟现实实验环境，实现动作拆解、参数调节、效果观测，同步真实操作过程，提升学习兴趣和效率，降低教学与实验成本，积极实践线上线下教学相结合的个性化、智能化、泛在化实验教学新模式。
        </p>
        <b-row>
            <b-col
                lg="6"
                cols="12"
            >
                <img
                    class="img-info img-3ds-edu"
                    src="@/assets/images/3ds/edu/xiaokongyanshe-fushitu.png"
                >
            </b-col>
            <b-col
                lg="6"
                cols="12"
            >
                <img
                    class="img-info"
                    src="@/assets/images/3ds/edu/xiaokongyanshe-qianshitu.png"
                >
            </b-col>
        </b-row>
        <div class="teaching-multimedia-design">
            <el-tag
                effect="dark"
            >
                教学多媒体设计
            </el-tag>
            <p class="p justify">
                通过模型、动画、视频、游戏等多媒体形式丰富教学过程。
            </p>
            <ul class="solution-content-ul justify">
                <li>
                    配合教学内容，将静态图像和文字具象为多维模型或动画过程，演示结构组成和工作原理；
                </li>
                <li>建立教学元素的数字模型和工具模型，3D实时渲染，构建丰富的交互式教学场景；</li>
                <li>解决实体教学中不可及的内容，克服了时间、空间、成本等制约，虚实结合，以虚促实；</li>
                <li>通过任务驱动方式引导开展体验式学习，充分提升创新能力和实践能力，促进实践教学；</li>
                <li>促进信息技术与教育教学深度融合，助力人才培养和创新创业，推进教学产教融合。</li>
            </ul>
            <b-row>
                <b-col
                    lg="6"
                    cols="12"
                >
                    <video
                        src="@/assets/video/molecules_Trim.mp4"
                        class="video-info"
                        type="video/mp4"
                        controls="controls"
                        poster="@/assets/video/video1.png"
                    />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Edu',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '三维可视化解决方案 - 教育方向')
      }
}
</script>
<style lang="less" scoped>
.Edu{
    margin-bottom:6.25rem;
    .img-3ds-edu{
      margin-bottom: 30px;
    }
    .teaching-multimedia-design{
      margin-top: 3.125rem;
      .solution-content-ul{
        li{
          margin-left: 2em;
        }
      }
    }
}
</style>
